import AddressUtils from "@/services/utils/AddressUtils";

let floor = "1";
let door = "2";
const location = {
  id: 1801,
  name: "Hostafrancs",
  state: { id: 1650, name: "Barcelona" },
  city: { id: 1737, name: "Barcelona" },
  district: { id: 1799, name: "Sants-Montjuïc" },
  neighborhood: { id: 1801, name: "Hostafrancs" },
};

describe("AddressUtils", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });

  describe("floorDoor", () => {
    it("should return floor and door string", () => {
      // Given
      // When
      const result = AddressUtils.floorDoor(floor, door);
      // Then
      expect(result).toEqual("1-2");
    });
    it("should return an empty string", () => {
      // Given
      floor = "";
      door = "";
      // When
      const result = AddressUtils.floorDoor(floor, door);
      // Then
      expect(result).toEqual("");
    });
  });

  describe("locationName", () => {
    it("should return district and city name if they are not null", () => {
      // given
      // when
      const result = AddressUtils.locationName(location);
      const expectedResult = "Sants-Montjuïc, Barcelona";
      // then
      expect(result).toEqual(expectedResult);
    });

    it("should return neighborhood and city name if they are not null and district is null", () => {
      // given
      location.district.name = "";
      // when
      const result = AddressUtils.locationName(location);
      const expectedResult = "Hostafrancs, Barcelona";
      // then
      expect(result).toEqual(expectedResult);
    });

    it("should return city and state name if they are not null", () => {
      // given
      location.district.name = "";
      location.neighborhood.name = "";
      // when
      const result = AddressUtils.locationName(location);
      const expectedResult = "Barcelona, Barcelona";
      // then
      expect(result).toEqual(expectedResult);
    });

    it("should return null", () => {
      // given
      location.district.name = "";
      location.neighborhood.name = "";
      location.state.name = "";
      // when
      const result = AddressUtils.locationName(location);
      const expectedResult = null;
      // then
      expect(result).toEqual(expectedResult);
    });
  });
});
